import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Student/StudentJobHistoryModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import StudentNav from "./StudentNav";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";
toast.configure()
const StudentJobHistory = (props) => {
  Common.ValidLogin();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const history = useHistory();
  const newDate = new Date();
  const id1 = props.id;
  const show = props.show === true ? true : ''
  const [loader, showLoader, hideLoader] = useLoader();
  const [model, setModel] = useState(DefaultModel);
  const services = new service();
  const [industry, setIndustry] = useState([]);
  const [fromDate, setFromDate] = useState(newDate);
  const [toDate, setToDate] = useState(newDate);
  const [border, setBorder] = useState("xu-input");

  useEffect(() => {

    if (id1 && props.show === true) {
      BindIndustry();
      props.setShow2(false)
      GetById(id1);
    }
    else if (props.id2 === "0" && props.show === true) {
      BindIndustry();
      props.setShow2(false)

    }
    else if (props.match || !props) {
      BindIndustry();
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }
  }, [id1, show]);

  const BindIndustry = async () => {
    var res = await services.PostGetAll(ServiceAPI.IndustryGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true
    });
    const data = await res.result;
    data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    await setIndustry(data);

  }

  const ChangeHandler = (e) => {

    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else if (e.target.type === "select-one") {
      value[e.target.id] = e.target.options[e.target.selectedIndex].value;
    } else if (e.target.type === "textarea") {
      const inputText = e.target.value;

      // Count words, excluding symbols
      const words = inputText
        .trim()
        .match(/\b[a-zA-Z]+\b/g) || [];

      // Allow only if the word count is within limits
      if (words.length <= 300) {
        value[e.target.id] = e.target.value;
      } else {
        toast.error("Maximum word limit of 300 reached.", { theme: "colored", autoClose: 2000 });
      }
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };

  const UpadteDate = async (e, field) => {
    let value = {};
    value[field] = e;
    if (field === 'fromDate') {
      setFromDate(e);
      setBorder({ ...border, 'fromDate': "" })
    }
    else if (field === 'toDate') {
      setToDate(e);
      setBorder({ ...border, 'toDate': "" })
    }
    await setModel({ ...model, ...value });
  }

  const Create = async (e) => {
    e.preventDefault();
    var response = null;

    if (valid()) {
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.StudentJobHistoryCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.StudentJobHistoryUpdateApi, { ...model, "industrys": "" });
      }
      if (response && response.message === "Success") {
        if (props.id) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetStudentWorkExperience(Common.LoginId());
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          $(`#${props.modalid}`).click();
          setTimeout(() => {
            hideLoader();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetStudentWorkExperience(Common.LoginId());
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setModel(DefaultModel);
          $(`#${props.modalid}`).click();
          setTimeout(() => {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setTimeout(() => {
            history.push("/studentjobhistorylist");
            setModel(DefaultModel);
          }, 2000);

        }
      } else {
        hideLoader();
      }
    }
  };

  const GetById = async (id) => {
    if (!show) {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
    }
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.StudentJobHistoryGetApi, id);
    hideLoader()
    const banner = await res.result;
    await setModel(banner);
    setFromDate(new Date(banner.fromDate));
    setToDate(new Date(banner.toDate));
  };
  const valid = () => {
    let Error = [];
    let companyName = "";
    let position = "";
    let industryError = '';
    let responsibility = "";
    let startDate = ""
    let endDate = ""

    if (!Boolean(model.companyName)) {
      Error.push("Valid companyName required.");
      companyName = "borderred";
    }
    if (!Boolean(model.position)) {
      Error.push("Valid Position required.");
      position = "borderred";
    }
    if (!Boolean(model.industryId) || model.industryId === "0") {
      Error.push("Valid Industry required");
      industryError = "borderred";
    }
    if (!Boolean(model.responsibility)) {
      Error.push("Valid Role Description required.");
      responsibility = "borderred";
    }
    if (!Boolean(model.fromDate)) {
      Error.push("start Date is required");
      startDate = "borderred";
    }
    if (new Date(model.fromDate) > new Date(model.toDate)) {
      Error.push("Start Date Can Not Greater than End Date");
      startDate = "borderred";
    }
    if (new Date(model.toDate) > new Date()) {
      Error.push("End Date Can Not Greater than Current Date");
      endDate = "borderred";
    }
    if (!Boolean(model.toDate)) {
      Error.push("end Date is required");
      endDate = "borderred";
    }
    if (companyName || position || industryError || responsibility || startDate || endDate) {
      setBorder({ companyName, position, industryError, responsibility, startDate, endDate });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored" });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/studentjobhistorylist");
  }
  const DeleteJobhistory = async (id) => {
    const res = await services.Delete(ServiceAPI.StudentJobHistoryDeleteApi, id);
    if (res.message == "Success") {
      if (props.id) {
        hideLoader();
        props.GetStudentWorkExperience(Common.LoginId());
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000 })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');
        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Experience" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <StudentNav jobhistory="active"></StudentNav>}
          <div className="row">
            <div className="  mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">
                <div className="col-md-12">
                  <div className="label">Company Name<span className="required">*</span></div>
                  <input
                    type="text"
                    id="companyName"
                    className={border.companyName ? border.companyName : "xu-input"}
                    placeholder="company Name"
                    value={model.companyName}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="label">Position<span className="required">*</span></div>
                  <input
                    type="text"
                    id="position"
                    className={border.position ? border.position : "xu-input"}
                    placeholder="Position"
                    value={model.position}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">Industry<span className="required">*</span></div>
                  <select className={border.industryError ? border.industryError : "xu-input"} id="industryId" value={model.industryId} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      industry.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="label">IsPresent</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="isPresent"
                      checked={model ? model.isPresent : ''}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="col-md-6">
                  <div className="label">Start Date<span className="required">*</span></div>
                  <DatePicker
                    id="fromDate"
                    className={border.startDate ? border.startDate : "xu-input"}
                    selected={fromDate}
                    onChange={(e) => UpadteDate(e, 'fromDate')}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div className="col-md-6">
                  <div className="label">End Date<span className="required">*</span></div>
                  {model && model.isPresent ?
                    <DatePicker
                      id="toDate"
                      className={border.endDate ? border.endDate : "xu-input"}
                      selected={toDate}
                      minDate={fromDate}
                      onChange={(e) => UpadteDate(e, 'toDate')}
                      dateFormat="dd-MM-yyyy"
                      disabled
                    />
                    :
                    <DatePicker
                      id="toDate"
                      className={border.endDate ? border.endDate : "xu-input"}
                      selected={toDate}
                      minDate={fromDate}
                      onChange={(e) => UpadteDate(e, 'toDate')}
                      dateFormat="dd-MM-yyyy"
                    />
                  }

                </div>
                <div className="col-md-12">
                  <div className="label">Role Description<span className="required">*</span>{`(${model.responsibility.trim().match(/\b[a-zA-Z]+\b/g)?.length || 0}/300)`}</div>
                  <textarea
                    type="text"
                    id="responsibility"
                    className={border.responsibility ? border.responsibility : "xu-textarea"}
                    placeholder="Role Description"
                    value={model.responsibility}
                    onChange={(e) => ChangeHandler(e)}
                    row="8"
                  // minLength={100}
                  // maxLength={300}
                  />
                </div>
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteJobhistory(model.id)}
                  >

                  </a>
                </div> : ""}
                <div className="col-md-12 spacing">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );
};

export default StudentJobHistory;
